import { fetchJson } from 'helpers/wordpress'
// import { getApiURL } from 'helpers/middleware'

const submitCalculatorForm = async formData => {
    const data = await fetchJson(
        'https://prod-53.westeurope.logic.azure.com:443/workflows/6488bdf611864f0ea7232ef5c97c798e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=KdEf6uJMHhVhy49GdySmxZpTbF2o7Xgwy33BD4Gy9j4',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...formData })
        }
    )
    return data
}

export default submitCalculatorForm
